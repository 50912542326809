.ymaps-2-1-79-gotoymaps__container,
.ymaps-2-1-79-copyright__wrap,
.ymaps-2-1-79-gototech{
    display:none !important;
}
.title_sp19{
    padding:5px 10px;
    margin-left: 40px;
    /* background: red; */
}
.text_map{
    position:relative !important;
    width:auto !important;
    display: inline-block;
    min-width: 130px !important; 
    max-width:200px;
    height:auto !important;
    left:0 !important; top:10px;
    padding:4px;
    border-radius: 4px;
    color:green !important;
    background-color:#fff !important;
}
.text_map_name{
    font-size: 12px;
    margin-bottom: 1px;
    white-space: nowrap;
    
    /* text-wrap:break-word; */
}
.text_map::after{
    content:"";
    position: absolute;
    width:10px;
    height:10px;
    top:32px;
    left:31%;
    border-radius:1px;
    transform:rotateZ(45deg);
    background-color:#fff !important;
}
.gmnoprint{
    top: 5px !important;
    height: 40px;
}
.gmnoprint div{
    display: block;
}
div.gm-style-cc{
    display: none;
}
.gm-style-mtc ul li span {
    position: relative;
    top: -4px;
}