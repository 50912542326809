.timeline_comp{
    width: 600px;
    /* background-color:red; */
}
.timeline_inner{
    display: inline-block;
    margin-left: 0px;
    margin-top:15px;
    margin-bottom:15px;
}
li::before{
    display: none;
}
.timeline_items{
    font-size: 14px;
}

