.MuiDrawer-paperAnchorDockedLeft {
    border-right: none!important;
}
.MuiPaper-rounded {
    border-radius: 0!important;
}
.MuiListItemIcon-root{
    min-width:48px !important;
}
.makeStyles-paper-27{
  height:100% !important;
}
.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root{
  padding-right: 0 !important;  
}
.css-i4bv87-MuiSvgIcon-root{
  margin-right: 2px !important;
}
.bar-menu span{
    font-size: 15px !important;
   
}

.makeStyles-paper-27{
    /* overflow: hidden !important; */
    overflow:auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 16px;
  
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgb(95, 95, 95);
  
}

::-webkit-scrollbar-thumb {
  background-color: rgb(133, 132, 132);
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(112, 112, 112);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}