.wrapper{
    display: flex;
    gap: 10px;
}
.title_19sp{
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 4px;
    padding:4px 10px;
    background: #1ca39d;
    color:#fff;
    border-radius: 40px;
    background: rgb(67, 132, 158);
}
.header_goods_pr{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table_body_20{
    position: relative;
}
button.bton_ppr{
    width: auto; height: 34px;
    font-size: 0.8rem !important;
    position: absolute;
    top: -3px; right:50px;
    padding: 12px;
}
button.noprvc.second{
    padding:5px 15px 0;
    font-size: 0.8rem;
    position: absolute;
    top: 1px; right: 110px;
}
button.noprvc{
    width: 100px; height: 29px;
    font-size: 0.8rem !important;
    padding:5px 0;
    border-radius: 3px;
    margin-bottom: 4px;
    font-size: 15px;
}
button.round{
    width: 36px; height: 30px;
    position: relative;
    top:-4px; right: 5px;
    padding-left: 2px;
}
.title_ts20{
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 10px;
    position: sticky;
    top:0px;
    background: #214746;
    color: #fff;
}
.ul_ts20{
    list-style-type:none;
    padding:0 10px;
    min-height: 500px;
    /* background: red; */
}
.ul_ts20 li{
    display: flex;
    align-items: center;
    padding:4px 9px;
    border-bottom: 1px solid #ccc;
    /* background: red; */
}
.number_inp_123_2{
    width: 90px;
    padding: 4px 2px;
    /* pointer-events:none; */
    /* opacity:0.7 */
}
.line_id_23{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background: red; */
}
.category_23{
    margin-left: auto;
}
.ul_ts20 li input{
    margin-right: 5px;
}
.btn_oop{
    width: 100px;
    padding:0 10px;
    border:none;
    border-radius: 4px;
    margin-top: 0px;
    margin-left: 5px;
    background: transparent;
    color:#fff;
    cursor: pointer;
    text-transform: uppercase;
    /* background: red; */
}
.line_342{
    border-bottom: 1px solid #ccc;
}
.btn_oop_wrapper{
    width: 100%;
    position: sticky;
    bottom: -1px;
    /* padding: 3px; */
    background: #214746;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.arrow_line{
    transform: scale(0.7);
    border-radius: 50%;
    padding:10px;
    box-sizing: content-box;

    /* background: red; */
}
.arrow_line.disabled{
    color:rgb(97, 117, 121);
}
.arrow_line:hover{
    background-color: rgb(61, 84, 88);
}
.btn_oop_wrap_13{
    width: 120px;
    display: flex;
    /* background:red; */
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ccc;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

.number_inp_123{
    outline: none;
}
.head_ete{
    position: relative;
}
.close_ete{
    position: absolute;
    width: 10px;
    top:4px; right:20px;
    color: #fff;
    cursor:pointer;
}
.wrap_table_2{
    position: relative;
    /* display: inline-block; */

    /* position: re; */
}
.btn-234{
    position: absolute;
    top: 1px; right: 110px;
    /* width: 100px; height: 28px; */
}
